<template>
    <div>
        <component 
            :is="actionsComponent" 
            :item="item"
            :isAuthor="isAuthor"
            :recordLoading="recordLoading"
            :restartConference="restartConference"
            :closeConference="closeConference"
            :inviteLink="inviteLink"
            :share="share"
            :deleteConference="deleteConference"
            :openEdit="openEdit"
            :activity="activity"
            :conferenceLink="conferenceLink"
            :closeDrawer="closeDrawer"
            :openRec="openRec" />
        <CreateDrawer 
            :emded="true"
            :parentVisible="editDrawer"
            :zIndex="1050"
            :closeDrawer="closeEditDrawer"
            :meeting="item" />
        <RecordDrawer 
            v-model="meetingRecVisible" 
            :id="item.id"/>
    </div>
</template>

<script>
import CreateDrawer from './CreateDrawer.vue'
import RecordDrawer from './RecordDrawer.vue'
import eventBus from '../utils/eventBus'
import globalEventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
export default {
    components: {
        CreateDrawer,
        RecordDrawer
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        page_name: {
            type: String,
            default: 'page_list_meetings.PlannedMeetingModel'
        },
    },
    data() {
        return {
            editDrawer: false,
            recordLoading: false,
            meetingRecVisible: false,
            activity: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        isMobile() {
            return this.$store.state.isMobile
        },
        isAuthor() {
            if(this.user && this.user.id === this.item.author.id) {
                return true
            } else
                return null
        },
        actionsComponent() {
            if(this.isMobile)
                return () => import ('./ActionsList.vue')
            else    
                return () => import ('./ActionsTable.vue')
        }
    },
    methods: {
        conferenceLink() {
            this.activity = false
            window.open(this.item.target, '_blank').focus()
        },
        openActionsDrawer() {
            this.activity = true
        },
        closeDrawer() {
            this.activity = false
        },
        closeEditDrawer() {
            this.editDrawer = false
        },
        openEdit() {
            this.activity = false
            this.editDrawer = true
        },
        openRec() {
            this.activity = false
            this.meetingRecVisible = true
        },
        async restartConference() {
            try{
                await this.$http(`meetings/${this.item.id}/restart/`)
                eventBus.$emit(`RESTART_CONFERENCE`, this.item.id)
                globalEventBus.$emit(`table_row_${this.page_name}`, {
                    action: 'update',
                    row: { 
                        ...this.item,
                        status: 'new'
                    }
                })

            }
            catch(e){
                console.error(e)
            }
        },
        closeConference() {
            this.activity = false
            this.$confirm({
                title: 'Предупреждение',
                content: 'Вы действительно хотите завершить конференцию?',
                zIndex: 5000,
                cancelText: 'Закрыть',
                okText: 'Завершить',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/meetings/end_meeting/', { id: this.item.id }).
                            then(() => {
                                this.$message.success('Конференция завершена')
                                eventBus.$emit(`END_CONFERENCE`, this.item.id)

                                globalEventBus.$emit(`table_row_${this.page_name}`, {
                                    action: 'update',
                                    row: { 
                                        ...this.item,
                                        status: 'ended'
                                    }
                                })

                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                },
                onCancel() {}
            })
        },
        inviteLink() {
            try {
                this.activity = false
                navigator.clipboard.writeText(this.item.invite_link)
                this.$message.success('Ссылка скопирована')
            } catch(e) {
                console.log(e)
            }
        },
        share() {
            this.activity = false
            this.$store.commit('share/SET_SHARE_PARAMS', {
                model: 'meetings.PlannedMeetingModel',
                shareId: this.item.id,
                object: this.item,
                shareUrl: `${window.location.origin}/?meeting=${this.item.id}`,
                shareTitle: `Конференция - ${this.item.name}`
            })
        },
        deleteConference() {
            this.activity = false
            this.$confirm({
                title: 'Предупреждение',
                content: 'Вы действительно хотите удалить конференцию?',
                zIndex: 5000,
                cancelText: 'Закрыть',
                okText: 'Удалить',
                okType: 'danger',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', [{ id: this.item.id, is_active: false }])
                            .then(() => {
                                this.$message.success('Конференция удалена')
                                eventBus.$emit(`reload_list_${this.page_name}`, true)
                                globalEventBus.$emit(`table_row_${this.page_name}`, {
                                    action: 'delete',
                                    row: { id: this.item.id }
                                })
                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                },
                onCancel() {}
            })
        }
    }
}
</script>